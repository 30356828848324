<template>
  <div class="public-page market-page">
    <van-nav-bar title="市场信息" />

    <div class="main-container">
      <van-tabs color="steelblue" v-model="activeCategory" @change="tabChange">
        <van-tab v-for="el in categorys" :name="el.name" :title="el.name" :key="el.id"></van-tab>
      </van-tabs>

      <div class="product-list">
        <div class="product-item" v-for="el in products" :key="el.id" @click="jumpDesc(el.id)">
          <div class="top flex">
            <div class="flex">
              <div class="name">{{ el.name }}</div>
            </div>
            <div class="flex">
              <div class="price" v-if="el.price">{{ el.price }}元 / 吨</div>
              <div class="price" v-else>暂无报价</div>
              <div class="right">
                <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 12L31 24L19 36" stroke="#666" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="bottom">
            <div class="left flex default">
              <div class="location" v-for="(sub, i) in el.locations" :key="i">{{ sub }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCategorys, getProducts } from '@/api/market'

export default {
  data() {return {
    categorys: [],
    products: [],
    activeCategory: ""
  }},
  created() {
    this.getCategorys()
  },
  methods: {
    async getCategorys() {
      const categorys = await getCategorys()

      this.categorys = categorys.data
      this.getProducts(this.categorys[0].id)
      this.activeCategory = this.categorys[0].name
    },
    async getProducts(id) {
      const res = await getProducts(id)

      this.products = res.data
      // res.data[0].location = "天王盖地虎"
    },
    tabChange(category) {
      const find = this.categorys.filter(el => el.name === category)
      
      console.log(find)
      this.getProducts(find[0].id)
    },
    jumpDesc(id) {
      this.$router.push("/product-desc/" + id)
    }
  }
}
</script>

<style scoped>
.product-list {
  margin-top: 20px;
}
.product-item {
  padding: 12px 25px;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px dashed rgba(255,255,255,.03);
}
.product-item .top {
  margin-bottom: 10px;
}
.product-item:active {
  background-color: rgba(255,255,255,.04);
}
.product-item .name {
  line-height: 23px;
}
.product-item .location {
  margin-right: 6px;
  font-size: 12px;
  background-color: rgba(70, 130, 180, .1);
  padding: 0 6px;
  border-radius: 8px;
  color: steelblue;
  line-height: 23px;
}
.product-item .right {
  margin-left: 7px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
