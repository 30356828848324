<template>
  <div class="public-page home-page">
    <van-nav-bar title="实时信息" />

    <div class="main-container">
      <div class="oil-list">

        <div class="oil-item">
          <div class="oil-sub-row top">
            <div class="label">WTI原油期货CFD (CL)</div>
            <div class="value">{{ oilTop.value }}</div>
          </div>
          <div class="oil-sub-row center">
            <div class="label">变动</div>
            <div class="value">{{ oilTop.change }} ({{ oilTop.changePercent }}%)</div>
          </div>
          <div class="oil-sub-row bottom">
            <div class="label">更新时间</div>
            <div class="value">{{ oilTop.time }}</div>
          </div>
        </div>

        <div class="oil-item">
          <div class="oil-sub-row top">
            <div class="label">布伦特原油CFD (CL)</div>
            <div class="value">{{ oilCenter.value }}</div>
          </div>
          <div class="oil-sub-row center">
            <div class="label">变动</div>
            <div class="value">{{ oilCenter.change }} ({{ oilCenter.changePercent }}%)</div>
          </div>
          <div class="oil-sub-row bottom">
            <div class="label">更新时间</div>
            <div class="value">{{ oilCenter.time }}</div>
          </div>
        </div>

        <div class="oil-item">
          <div class="oil-sub-row top">
            <div class="label">上海SC原油</div>
            <div class="value">{{ oilBottom.value }}</div>
          </div>
          <div class="oil-sub-row center">
            <div class="label">变动</div>
            <div class="value">{{ oilBottom.change }} ({{ oilBottom.changePercent }}%)</div>
          </div>
          <div class="oil-sub-row bottom">
            <div class="label">更新时间</div>
            <div class="value">{{ oilBottom.time }}</div>
          </div>
        </div>
        
      </div>

      <div class="time-line-list">
        <van-pull-refresh v-model="steamLoading" @refresh="onRefreshSteam">
          <timeline-list :listData="steamData"></timeline-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { getOIL, getSteamData } from '@/api/home'
import { checkUpdate } from '@/api/update'
import timelineList from '@/components/timeline-list.vue'

export default {
  components: { timelineList },
  data() {return {
    oilTop: {},
    oilCenter: {},
    oilBottom: {},
    timer: null,
    steamLoading: false,
    steamData: []
  }},

  methods: {
    async getOIL() {
      const res = await getOIL()

      this.parseOILString(res.data)
    },

    async getSteamData() {
      const res = await getSteamData()

      const newData = res.data.map(el => {
        return {
          time: el.time,
          pic: el.pic,
          ...this.parseExtractTitleAndContent(el.content)
        }
      })
      this.steamData = newData
    },

    async onRefreshSteam() {
      await this.getSteamData()

      this.steamLoading = false
      this.$toast("刷新成功")
    },

    parseExtractTitleAndContent(originString) {
      // 匹配【标题】
      const titleMatch = originString.match(/【(.*?)】/)
      let title = null
      if (titleMatch && titleMatch.length > 1) {
        title = titleMatch[1]
        originString = originString.replace(/【.*?】/, '')
      }

      // 删除所有标签
      const content = originString.replace(/<[^>]+>/g, '')

      return { title, content }
    },

    parseOILString(originString) {
      const hq_str_hf_CL = originString.match(/var hq_str_hf_CL="(.*)";/)[1]
			const hq_str_hf_OIL = originString.match(/var hq_str_hf_OIL="(.*)";/)[1]
			const hq_str_SC0 = originString.match(/var hq_str_nf_SC0="(.*)";/)[1]

			const wtiData = hq_str_hf_CL.split(",")
			const brentData = hq_str_hf_OIL.split(",")
			const shanghaiData = hq_str_SC0.split(",")

      this.oilTop = this.updateData("wti", wtiData)
      this.oilCenter = this.updateData("brent", brentData)
      this.oilBottom = this.updateData("shanghai", shanghaiData)
    },

    updateData(type, data) {
      let price, prevPrice

      if (type === "shanghai") {
				price = parseFloat(data[7])
				prevPrice = parseFloat(data[10])
			} else {
				price = parseFloat(data[0])
				prevPrice = parseFloat(data[7])
			}

      const change = price - prevPrice
      const changePercent = (change / prevPrice) * 100
      const updateTime = new Date().toLocaleString()

      return {
        value: price,
        change: change.toFixed(2),
        changePercent: changePercent.toFixed(2),
        time: updateTime
      }
    },

    handleVisiable(e) {
      if (e.target.visibilityState === "hidden") {
        clearInterval(this.timer)
        this.timer = null
      }
      
      else {
        this.timer = setInterval(() => {
          this.getOIL()
        }, 3000)
      }
    },

    // 检查更新
    async checkUpdate() {
      const res = await checkUpdate()

      if (res.data.download_url) {
        // 需要更新
        this.$dialog.confirm({
          title: '更新提示',
          message: `检测到新版本: v${ res.data.version }, 点击安装`,
          confirmButtonText: '下载',
          cancelButtonText: '暂不更新',
        })
        .then(() => {
          const a = document.createElement("a")
          a.href = res.data.download_url
          a.target = "_blank"
          a.download = "Update_H348GRW7_" + res.data.version + ".apk"
          
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch(() => {})
      }
    }
  },

  created() {
    this.getOIL()
    this.getSteamData()
    // this.checkUpdate()

    this.timer = setInterval(() => {
      this.getOIL()
    }, 3000)
  },

  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style scoped>
.time-line-list {
  margin-top: 20px;
}
.main-container {
  padding: 15px;
}
.oil-item {
  border-radius: 10px;
  background-image: linear-gradient(to top left, rgba(255,255,255,.06), rgba(255,255,255,.02));
  padding: 8px 0 8px 15px;
  color: #efefef;
  font-size: 14px;
  margin-bottom: 10px;
}
.oil-item .oil-sub-row {
  line-height: 30px;
  border-bottom: 1px solid rgba(255,255,255,.03);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}
.oil-item .oil-sub-row:last-child {
  border-bottom-color: transparent;
}
.oil-item .top .label {
  color: steelblue;
}
.oil-item .top .value {
  color: #dc3545;
}
.oil-item .center .label {
  color: brown;
}
.oil-item .center .value {
  color: #339966;
}
.tab-title {
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}
</style>
