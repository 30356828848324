import Vue from 'vue'
import VueRouter from 'vue-router'

import layout from '@/layout'
import home from '@/layout/home'
import tool from '@/layout/tool'
import my from '@/layout/my'
import market from '@/layout/market'

import login from '@/views/login.vue'
import updateDownload from '@/views/update-download.vue'
import register from '@/views/register.vue'
import customCode from '@/views/custom-code.vue'
import productdesc from '@/views/product-desc.vue'
import applyFor from '@/views/apply-for.vue'
import certification from '@/views/certification.vue'
import KefuChat from '@/views/KefuChat.vue'

// import { checkUpdate } from '@/api/update'

import store from '@/store'
import { Dialog } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/tool',
        name: 'tool',
        component: tool
      },
      {
        path: '/market',
        name: 'market',
        component: market
      },
      {
        path: '/my',
        name: 'my',
        component: my
      }
    ]
  },
  {
    path: '/update_download',
    name: updateDownload,
    component: updateDownload
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/custom-code',
    name: 'customCode',
    component: customCode
  },
  {
    path: '/product-desc/:id',
    name: 'productdesc',
    component: productdesc
  },
  {
    path: '/apply-for',
    name: 'applyFor',
    component: applyFor
  },
  {
    path: '/certification',
    name: 'certification',
    component: certification
  },
  {
    path: '/chat',
    name: 'chat',
    component: KefuChat
  }
]

const router = new VueRouter({
  routes
})

const toPaths = ['/login', '/register', '/custom-code', '/update_download']
router.beforeEach(async (to, from, next) => {
  // 检查更新并强制用户更新
  // if (toPaths.indexOf(to.path) === -1) {
  //   const res = await checkUpdate()

  //   if (res.data) {
  //     Dialog.alert({
  //       title: '更新提示',
  //       message: `检测到新版本: v${ res.data.version }, 点击安装`,
  //       confirmButtonText: '下载',
  //     })
  //     .then(() => {
  //       const a = document.createElement("a")
  //       a.href = res.data.download_url
  //       a.download = "Update_H348GRW7_" + res.data.version + ".apk"
        
  //       document.body.appendChild(a)
  //       a.click()
  //       document.body.removeChild(a)
  
  //       next("/update_download")
  //     })
  //   }
  // }

  // 检查用户是否已登录
  const isAuthenticated = !!store.getters.getToken

  // 如果用户已登录，则允许访问其他页面
  if (isAuthenticated) {
    next()
  }
  
  else {
    // 如果用户未登录且要访问的页面不是登录页或注册页，则重定向到登录页
    if (toPaths.indexOf(to.path) === -1) {
      next('/login')
    } else {
      // 如果用户未登录且要访问的页面是登录页，则继续访问
      next()
    }
  }
})


export default router
