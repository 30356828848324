<template>
  <div class="public-page my-page">
    <van-nav-bar title="我的" />

    <div class="avatar">
      <img src="https://image.meiye.art/pic_1632645127962b4kNxcoJA2Q4yaS7-ugAg">
      <div class="nickname flex center">
        <div class="sf-tab" :class="{ gys: checkList[0].show }">{{ checkList[0].show ? "供应商" : "普通用户" }}</div>
        <div class="username">{{ username }}</div>
      </div>
    </div>

    <div class="check-list">
      <div class="check-item flex"
        v-show="el.show === undefined || el.show === false" v-for="(el, i) in checkList" :key="i" @click="defs(el.jump)">
        <div class="left flex">
          <div class="ico flex" v-html="el.ico"></div>
          <div class="label">{{ el.label }}</div>
        </div>
        <div class="right">
          <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 12L31 24L19 36" stroke="rgba(255,255,255,.3)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
      </div>
    </div>

    <div class="logout" @click="logout">退出登录</div>
  </div>
</template>

<script>
import store from '@/store'
import { vaildIdentity } from '@/api/market'
// import { checkUpdate } from '@/api/update'
import { mapState } from 'vuex'

export default {
  data() {return {
    checkList: [
      {
        ico: `<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 11V37" stroke="steelblue" stroke-width="4" stroke-linecap="round"/><path d="M33 4V44" stroke="steelblue" stroke-width="4" stroke-linecap="round"/><path d="M6 11V37" stroke="steelblue" stroke-width="4" stroke-linecap="round"/><path d="M42 15V33" stroke="steelblue" stroke-width="4" stroke-linecap="round"/><path d="M15 18V30" stroke="steelblue" stroke-width="4" stroke-linecap="round"/></svg>`,
        label: "申请成为供应商",
        show: false,
        jump: "router:/apply-for"
      },
      {
        ico: `<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.4307 12.0391C37.702 7.38257 31.1539 4.5 23.9184 4.5C16.7254 4.5 10.2118 7.34876 5.48975 11.9571" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.6665 29.4745V29.4168C6.6665 19.8439 14.4269 12.0835 23.9998 12.0835" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M31.1694 13.6309C37.1649 16.3582 41.3333 22.4006 41.3333 29.4166V29.4296" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.25 36.9998V29.4165C14.25 24.0317 18.6152 19.6665 24 19.6665C29.3848 19.6665 33.75 24.0317 33.75 29.4165V36.9998" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5259 43.5C19.4888 43.0154 20.7498 40.9456 20.7498 39.196C20.7498 37.3354 20.7498 34.4367 20.7498 30.5C20.7498 28.7051 22.2049 27.25 23.9998 27.25C25.7947 27.25 27.2498 28.7051 27.2498 30.5V39.196" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
        label: "实名认证",
        jump: "router:/certification"
      },
      {
        ico: `<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="20" fill="none" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 17H20V24H23H24C27 24 28 22 28 20.5C28 19 27 17 24 17Z" fill="none"/><path d="M20 31V24M20 24L20 17H24C27 17 28 19 28 20.5C28 22 27 24 24 24H23M20 24H23M28 31L23 24" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
        label: "关于我们",
        jump: "router:/custom-code"
      },
      {
        ico: `<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42 30V24.4615C42 14.2655 33.9411 6 24 6C14.0589 6 6 14.2655 6 24.4615V30" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M34 32C34 29.7909 35.7909 28 38 28H42V42H38C35.7909 42 34 40.2091 34 38V32Z" fill="none" stroke="#4682b4" stroke-width="4" stroke-linejoin="round"/><path d="M42 32H44C45.1046 32 46 32.8954 46 34V36C46 37.1046 45.1046 38 44 38H42V32Z" fill="#4682b4"/><path d="M6 32H4C2.89543 32 2 32.8954 2 34V36C2 37.1046 2.89543 38 4 38H6V32Z" fill="#4682b4"/><path d="M6 28H10C12.2091 28 14 29.7909 14 32V38C14 40.2091 12.2091 42 10 42H6V28Z" fill="none" stroke="#4682b4" stroke-width="4" stroke-linejoin="round"/></svg>`,
        label: "联系客服",
        jump: "router:/custom-code"
      },
      {
        ico: `<svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 43C17.2742 41.2327 13.3325 35.1851 11.3597 31.6428C10.5079 30.1134 10.9566 28.2347 12.3236 27.1411C13.8473 25.9222 16.0438 26.0438 17.4236 27.4236L19 29V17.5C19 16.1193 20.1193 15 21.5 15C22.8807 15 24 16.1193 24 17.5V23.5C24 22.1193 25.1193 21 26.5 21C27.8807 21 29 22.1193 29 23.5V25.5C29 24.1193 30.1193 23 31.5 23C32.8807 23 34 24.1193 34 25.5V27.5C34 26.1193 35.1193 25 36.5 25C37.8807 25 39 26.1193 39 27.5V35.368C39 36.4383 38.7354 37.496 38.1185 38.3707C37.0949 39.8219 35.255 42.0336 33 43C29.5 44.5 26.3701 44.6343 22 43Z" fill="none" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M36 12L32 16L28 12" stroke="#4682b4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 13C14 8.02944 18.0294 4 23 4C27.9706 4 32 8.02944 32 13C32 13.5641 31.9481 14.1161 31.8488 14.6514" stroke="#4682b4" stroke-width="4" stroke-linecap="round"/></svg>`,
        label: "检查更新",
        jump: "def:checkUpdate"
      }
    ],
  }},
  computed: {
    ...mapState(['username'])
  },
  created() {
    this.vaildIdentity()
  },
  methods: {
    logout() {
      store.commit("setToken", "")
      store.commit("setUsername", "")
      this.$router.push("/login")
    },
    async vaildIdentity() {
      const res = await vaildIdentity()

      this.checkList[0].show = Boolean(res.data.nick)
    },
    defs(jump) {
      const formatResult = jump.split(":")

      if (formatResult[0] === "router") {
        this.$router.push(formatResult[1])
      }

      else if (formatResult[0] === "def") {
        this[formatResult[1]]()
        // this[formatResult[1]]()
      }
    },
    // 检查更新
    async checkUpdate() {
      const res = await checkUpdate()

      if (res.data) {
        // 需要更新
        this.$dialog.confirm({
          title: '更新提示',
          message: `检测到新版本: v${ res.data.version }, 点击安装`,
          confirmButtonText: '下载',
          cancelButtonText: '暂不更新',
        })
        .then(() => {
          const a = document.createElement("a")
          a.href = res.data.download_url
          a.target = "_blank"
          a.download = "Update_H348GRW7_" + res.data.version + ".apk"
          
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
        .catch(() => {})
      } else {
        this.$toast("当前已是最新版本")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-page {
  padding-bottom: 50px;
}
.sf-tab {
  margin-right: 10px;
  font-size: 12px;
  color: steelblue;
  background-color: rgba(70, 130, 180, .1);
  line-height: 26px;
  padding: 0 6px;
  border-radius: 6px;

  &.gys {
    background-color: rgba(165, 42, 42, .1);
    color: #dc3545;
  }
}
.check-list {
  padding: 0 20px;

  .check-item {
    color: #fff;
    background-color: rgba(255,255,255,.01);
    transition: .2s;
    padding: 0 15px;
    border-radius: 8px;
    height: 48px;
    margin-bottom: 10px;

    .ico {
      margin-right: 15px;
    }

    .label {
      font-size: 15px;
      color: rgba(255,255,255,.7);
    }

    &:active {
      background-color: rgba(255,255,255,.04);
    }
  }
}
.logout {
  width: 120px;
  margin: 20px auto;
  text-align: center;
  background-color: steelblue;
  color: #fff;
  line-height: 36px;
  border-radius: 10px;
  font-size: 14px;
}
.avatar {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .nickname {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
  }
}
</style>
