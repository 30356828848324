<template>
  <div class="public-page custom-code-page">
    <van-nav-bar title="联系客服" left-arrow @click-left="$router.back()"/>
    <div class="iframe-container">
      <iframe src="https://kefu.yeyouxing.cn/chatIndex?kefu_id=kefu2" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openChat() {
      this.$router.push('/chat');
    }
  }
}
</script>

<style scoped>
.public-page {
  height: 100vh;
  display: flex;/*改了这个22，23行*/
  flex-direction: column;
  /* 这里可以添加您的公共样式 */
}

.custom-code-page {
  /* 这里可以添加您的自定义样式 */
}

.iframe-container {
  flex: 1; 
  overflow: hidden; /* 防止 iframe 内容超出容器 */
}

.iframe-container iframe {
  width: 100%;
  height: 100%; /* 设置 iframe 的高度为 100% */
  border: none; /* 移除 iframe 的边框 */
}
</style>