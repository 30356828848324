<template>
  <div class="public-page product-desc-page">
    <van-nav-bar title="商品详情" left-arrow @click-left="$router.back(-1)" />

    <div class="main-container" v-if="descData.length">
      <div class="model">
        <div class="name" style="font-size: 14px;" v-if="descData.length">
          <span class="title">{{ descData[0].name }}</span>
          <span class="tab">一吨起订</span>
        </div>

        <div class="price-list">
          <div class="price-item" @click="toggleCurrentProduct(el, i)" :class="{ active: currentActive === i ,'strikethrough1': shouldStrikeThroughitem(el)}" v-for="(el ,i) in descData" :key="i">
            <div class="location" :class="{ 'strikethrough2': shouldStrikeThroughlocation(el) }">{{ el.location }}</div>
            <div class="price" :class="{ 'strikethrough': shouldStrikeThroughPrice(el) }">￥{{ el.price }}</div>
          </div>
        </div>
      </div>

      <div class="model p-ic-list-cpnt">
        <div class="p-date">
          <div class="flex default">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="none" stroke="#efefef" stroke-width="4" stroke-linejoin="round"/><path d="M24.0084 12.0001L24.0072 24.0089L32.4866 32.4883" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
            <span>交易时间: {{ !current.trading ? '暂无' : '' }}</span>
          </div>
          <span v-if="current.trading" style="display: block; margin-top: 10px">{{ current.trading }}</span>
        </div>

        <div class="p-ic-list flex">
          <div class="p-ic-item flex default">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 22L10 12L4 22" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10 28C13.3137 28 16 25.3137 16 22H4C4 25.3137 6.68629 28 10 28Z" fill="none" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M44 22L38 12L32 22" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M38 28C41.3137 28 44 25.3137 44 22H32C32 25.3137 34.6863 28 38 28Z" fill="none" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M24 6V42" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 12H24H38" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 12H24H38" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M38 42H24H10" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
            <span>库存数量：{{ current.count }}</span>
          </div>

          <div class="p-ic-item flex default">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44.0002C35.0457 44.0002 44 35.0459 44 24.0002C44 12.9545 35.0457 4.00024 24 4.00024C12.9543 4.00024 4 12.9545 4 24.0002C4 35.0459 12.9543 44.0002 24 44.0002Z" stroke="#efefef" stroke-width="4"/><path d="M6 30.9856C8.63192 32.041 10.5266 32.041 11.6839 30.9856C13.4199 29.4025 11.9219 24.5978 14.3532 23.2727C16.7844 21.9476 20.4886 27.8214 23.9508 25.8887C27.4129 23.9559 23.6246 18.8023 26.0272 16.713C28.4298 14.6237 31.554 16.98 32.1001 13.4865C32.6462 9.99304 29.5521 11.5082 28.9584 8.20693C28.5625 6.00611 28.5625 4.84884 28.9584 4.73511" stroke="#efefef" stroke-width="4" stroke-linecap="round"/><path d="M29.0209 43.3505C27.1468 41.4325 26.4721 39.6497 26.9969 38.0019C27.7841 35.5303 29.0826 35.6764 29.6488 34.1482C30.2149 32.6199 28.6156 30.4433 32.1643 28.5826C34.5301 27.3421 37.783 28.7794 41.9228 32.8944" stroke="#efefef" stroke-width="4" stroke-linecap="round"/></svg>
            </div>
            <span>货源地：{{ current.location ?? "无" }}</span>
          </div>

          <div class="p-ic-item flex default">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.93882 13.9336L23.0362 4.53011C23.6363 4.20002 24.3637 4.20002 24.9638 4.53011L42.0612 13.9336C43.0988 14.5043 43.4161 15.8467 42.7438 16.8215L25.6464 41.6127C24.8515 42.7654 23.1485 42.7654 22.3536 41.6127L5.25624 16.8215C4.58391 15.8467 4.90118 14.5044 5.93882 13.9336Z" fill="none" stroke="#efefef" stroke-width="4"/></svg>
            </div>
            <span>品质约定：{{ current.quality ? current.quality : "无" }}</span>
          </div>

          <div class="p-ic-item flex default">
            <div class="icon">
              <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.0234 6.68921C31.0764 4.97912 27.6525 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 20.3727 43.0344 16.9709 41.3461 14.0377" stroke="#efefef" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M31.9498 16.0503C31.9498 16.0503 28.5621 25.0948 27.0001 26.6569C25.438 28.219 22.9053 28.219 21.3432 26.6569C19.7811 25.0948 19.7811 22.5621 21.3432 21C22.9053 19.4379 31.9498 16.0503 31.9498 16.0503Z" fill="none" stroke="#efefef" stroke-width="4" stroke-linejoin="round"/></svg>
            </div>
            <span>配送日期：{{ current.delivery ? current.delivery : "无" }}</span>
          </div>
        </div>
      </div>

      <div class="model desc-list">
        <div class="top-title-text">商品说明</div>
        <ul>
          <li>
            <div class="top">划线价格</div>
            <div class="desc">由于地区、市场波动等影响，该划线价仅供参考</div>
          </li>
          <li>
            <div class="top">未划线价格</div>
            <div class="desc">
              商品或服务实时售价，具体成交价可能因营销活动等发生变化，最终以订单结算页价格为准，请您根据此决定是否购买。
            </div>
          </li>
          <li>
            <div class="top">如需了解详情，请联系客服</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-container" v-else>
      <div class="null-price">暂无报价</div>
    </div>

    <div class="submit" v-if="identity" @click="openForm">我要出价</div>
    <div class="submit second" v-else @click="$router.push('/custom-code')">了解详情</div>

    <div class="price-edit" :class="{ active: priceEditShow }">
        <van-nav-bar title="编辑出价信息" left-arrow @click-left="priceEditShow = false" />

        <van-cell>
          <van-field v-model="addform.price" label="价格" placeholder="请输入价格" />
          <van-field v-model="addform.location" label="货源地" placeholder="请输入货源地" />
          <van-field v-model="addform.count" label="库存数量" placeholder="库存数量" />
          <van-field v-model="addform.quality" label="品质约定" placeholder="请输入品质约定" />

          <van-cell title="选择配送日期" :value="addform.delivery" @click="show_delivery = true" />
          <van-calendar v-model="show_delivery" @confirm="confirmSelectDate($event, 'delivery', 'show_delivery')" :min-date="new Date('2000-01-01')" />

          <van-datetime-picker
            v-model="addform.trading_start"
            type="datetime"
            title="交易起始时间"
            visible-item-count="3"
            item-height="36"
            cancel-button-text=" "
            confirm-button-text=" "
          />

          <van-datetime-picker
            v-model="addform.trading_end"
            type="datetime"
            title="交易截止时间"
            visible-item-count="3"
            item-height="36"
            cancel-button-text=" "
            confirm-button-text=" "
          />
        </van-cell>

        <div class="submit" @click="udpatePrice">确定出价</div>
      </div>
  </div>
</template>

<script>
import { getProductDesc, vaildIdentity, udpatePrice } from '@/api/market'

export default {
  name: "productDesc",
  data() {return {
    currentActive: 0,
    priceEditShow: false,
    show_delivery: false,
    show: false,
    descData: [],
    active: "",
    current: {},
    identity: false,
    priceInput: "",
    addform: { 
      price: "", 
      product_id: "",
      location: "", 
      count: "", 
      quality: "", 
      delivery: "", 
      trading_start: new Date(),
      trading_end: new Date(),
      trading: ""
    }
  }},
  computed: {
    getId() {
      return this.$route.params.id
    }
  },
  methods: {
    shouldStrikeThroughlocation(el) {
      const today = new Date();
      const deliveryDate = new Date(el.delivery); // 获取配送日期

      // 计算两个日期之间的天数差
      const diffDays = Math.ceil(Math.abs((today - deliveryDate) / (1000 * 60 * 60 * 24)));

      // 如果配送日期大于两天，则返回 true，添加删除线样式
      return diffDays > 2;
    },
    shouldStrikeThroughitem(el) {
      const today = new Date();
      const deliveryDate = new Date(el.delivery); // 获取配送日期

      // 计算两个日期之间的天数差
      const diffDays = Math.ceil(Math.abs((today - deliveryDate) / (1000 * 60 * 60 * 24)));

      // 如果配送日期大于两天，则返回 true，添加删除线样式
      return diffDays > 2;
    },
    shouldStrikeThroughPrice(el) {
      const today = new Date();
      const deliveryDate = new Date(el.delivery); // 获取配送日期

      // 计算两个日期之间的天数差
      const diffDays = Math.ceil(Math.abs((today - deliveryDate) / (1000 * 60 * 60 * 24)));

      // 如果配送日期大于两天，则返回 true，添加删除线样式
      return diffDays > 2;
    },
    toggleCurrentProduct(el, i) {
      this.current = el
      this.currentActive = i
    },
    openForm() {
      this.priceEditShow = true

      this.addform = { 
        price: "", 
        product_id: "",
        location: "", 
        count: "", 
        quality: "", 
        delivery: "", 
        trading_start: new Date(),
        trading_end: new Date(),
        trading: ""
      }
    },
    formatDate(date, all, split_type) {
      const year = date.getFullYear()
      const month = ("0" + (date.getMonth() + 1)).slice(-2)
      const day = ("0" + date.getDate()).slice(-2)
      const hours = ("0" + date.getHours()).slice(-2)
      const minutes = ("0" + date.getMinutes()).slice(-2)
      const seconds = ("0" + date.getSeconds()).slice(-2)

      let dateString
      if (split_type) {
        dateString  = year + "年" + month + "月" + day + "日"
      } else {
        dateString = year + "-" + month + "-" + day
      }

      if (all) {
        dateString += " " + hours + ":" + minutes + ":" + seconds
      }

      return dateString
    },
    confirmSelectDate(date, type, show_) {
      this.addform[type] = this.formatDate(date)
      this[show_] = false
    },
    async getProductDesc() {
      const res = await getProductDesc(this.getId)

      res.data.forEach(el => {
        const times = el.trading.split(" & ")
        
        el.price = el.price.toLocaleString()
        el.trading = this.formatDate(new Date(times[0]), true, true) + " - " + this.formatDate(new Date(times[1]), true, true)
        el.delivery = this.formatDate(new Date(el.delivery))
      })
      this.descData = res.data
      this.current = res.data[0]
    },
    async vaildIdentity() {
      const res = await vaildIdentity()
      
      if (res.data.nick === 1) {
        this.identity = true
      } else {
        this.identity = false
      }
    },
    vaildPriceValue() {
      for(let i in this.addform) {
        if (i === "product_id" || i === "trading") {
          
        }

        else if (i === "trading_start" || i === "trading_end") {
          if (!this.addform[i]) {
            this.$toast("请选择好时间")
            return false
          }
        }

        else if (!this.addform[i].trim()) {
          this.$toast("输入框内容不能为空")
          return false
        }
      }

      if (isNaN(this.addform.price)) {
        this.$toast("价格请输入数字")
        return false
      }

      if (isNaN(this.addform.count)) {
        this.$toast("库存请输入数字")
        return false
      }

      this.addform.price = Number(this.addform.price)
      this.addform.count = Number(this.addform.count)
      this.addform.trading =
      this.formatDate(this.addform.trading_start, true) + " & " + this.formatDate(this.addform.trading_end, true)

      console.log(true)
      return true
    },
    async udpatePrice() {
      if (!this.vaildPriceValue()) return false
      const res = await udpatePrice({
        ...this.addform,
        product_id: this.getId
      })

      this.$toast("出价成功")
      this.priceEditShow = false

      this.getProductDesc()
      this.vaildIdentity()
    }
  },
  created() {
    this.getProductDesc()
    this.vaildIdentity()
  },
}
</script>

<style lang="less" scoped>
.null-price {
  color: #fff;
  text-align: center;
  margin-top: 100px;
}
.top-title-text {
  font-size: 14px;
  margin-bottom: 15px;
  border-bottom: 1px dashed rgba(255,255,255,.04);
  padding-bottom: 15px;
}
.desc-list li {
  margin-left: 15px;
  list-style: circle;
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 12px;

  .top {
    font-weight: 600;
    margin-bottom: 4px;
  }
  .desc {
    color: rgba(255,255,255,.4);
  }
}
.main-container {
  color: #fff;
}
.model {
  padding: 20px;
  border-bottom: 1px solid rgba(255,255,255,.02);
  background-color: rgba(255,255,255,.01);
  margin-bottom: 10px;
}
.price-list {
  margin-top: 15px;
}
.price-item {
  display: inline-block;
  font-size: 12px;
  padding: 3px 10px;
  background-color: rgba(255, 255, 255, .05);
  border: 1px solid rgba(255,255,255,.05);
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  color: rgba(255,255,255,.5);
  margin-bottom: 8px;

  &.active {
    color: steelblue;
    background-color: rgba(70, 130, 180, 0.2);
    border: 1px solid steelblue;
  }
}
.p-ic-list-cpnt {
  padding-bottom: 15px;
}
.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-ic-list {
  margin-top: 15px;
  flex-wrap: wrap;
  color: rgba(255,255,255,.6) !important;
}
.p-date {
  border-bottom: 1px dashed rgba(255,255,255,.04);
  padding-bottom: 15px;
}
.p-ic-item {
  width: 50%;
  margin-bottom: 15px;
}
.p-date, .p-ic-item {
  span {
    margin-left: 10px;
    font-size: 13px;
  }
}
span.tab {
  margin-left: 8px;
  background-color: brown;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
}
.desc-- {
  font-size: 12px;
  margin-top: 5px;
  color: rgba(255,255,255,.3);
}
.submit {
  position: fixed;
  bottom: 15px;
  width: calc(100% - 30px);
  left: 15px;
  background-color: steelblue;
  line-height: 40px;
  text-align: center;
  border-radius: 100px;
  transition: .1s;
  font-size: 14px;
  color: #fff;
}
.submit.second {
  background-color: rgb(25, 79, 123);
}
.submit:active {
  filter: brightness(70%);
}
.price-edit {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--themecolor);
  z-index: 1;
  display: none;
}
.price-edit.active {
  display: block;
}
.strikethrough {
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.475);
  font-weight: bold;
}
.strikethrough1 {
  position: relative;
  display: inline-block;
}
.strikethrough1::after {
  content: '价格已过期'; /* 水印内容 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); /* 半透明背景，透明度为 0 */
  color:rgba(255, 0, 0, 0.3); /* 半透明文字颜色 */
  font-size: 16px; /* 文字大小 */
  pointer-events: none; /* 防止水印影响点击事件 */
  font-weight: bold;
}
.strikethrough2 {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.475);
}

</style>
