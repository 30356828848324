import router from "@/router"
import store from "@/store"
import axios from "axios"
import { Toast } from "vant"

const instance = axios.create({
  baseURL: "https://api.yeyouxing.cn/",
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  const token = store.getters.getToken

  if (token) {
    config.headers.Authorization = "Bearer " + token
  }

  // 按需禁用加载动画
  if (!config.offLoading) {
    Toast.loading({
      message: "加载中..",
      duration: 0,
      forbidClick: true
    })
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
  const statusCode = response.data.code

  // 根据逻辑状态码按需拦截
  if (statusCode === 200) {
    Toast.clear()
    return response.data
  }

  else if (statusCode === 402) {
    Toast("身份验证失效，请重新登录")
    store.commit("setToken", "")

    router.push("/login")
  }

  else if (statusCode === 403) {
    Toast("仅限VIP用户使用")
    router.push("/custom-code")
  }

  else {
    Toast(response.data.message)
  }

  return Promise.reject(
    response.data.code, response.data.message
  )

}, function (error) {
  const errorMessage = error.response.data.message

  /**
   * 502 -> 网络错误
   */
  if (error.response.status === 502) {
    Toast("网络错误，请稍后重试")
  }
  
  else {
    Toast(errorMessage ?? error.message)
  }
  
  return Promise.reject(error)
})

export default instance
