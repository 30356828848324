<template>
  <div class="public-page update-download-page">
    <van-nav-bar title="更新下载" />

    <van-empty image="network" description="已开始下载，请自行查看安装..." />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
