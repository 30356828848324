<template>
  <div class="timeline-container">
    <ul class="timeline">
      <li class="timeline-event jin-flash-item" v-for="(el, i) in listData" :key="i">
        <label class="timeline-event-icon"></label>

        <div class="timeline-event-copy item-right">
          <div class="timeline-event-thumbnail">
            <div class="left">{{ el.time }}</div>
          </div>
          <div class="title" v-if="el.title">{{ el.title }}</div>
          <div class="desc" ref="timelineDesc">{{ el.content }}</div>
          
          <!-- 缩略图显示 -->
          <div v-if="el.pic && el.pic.trim() !== ''" class="right-pic img-intercept flash-pic" @click="enlargeImage(el.pic)">
            <div class="img-container show-shadow">
              <img :src="el.pic" alt="Image" lazy="loaded">
            </div>
          </div>

          <div class="expand-more" data-expand="false" :data-index="i" @click="toggleExpand($event)">
            <span>展开更多</span>
            <svg width="16" height="16" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36 18L24 30L12 18" stroke="rgb(133, 181, 221)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
        </div>
      </li>
    </ul>

    <!-- 大图显示模态框 -->
    <div v-if="enlargedImageSrc" class="image-modal" @click="closeEnlargedImage">
      <img :src="enlargedImageSrc" alt="Enlarged Image">
    </div>
  </div>
</template>

<script>
// 脚本部分保持不变
export default {
  props: ["listData"],

  data() {
    return {
      nodes: [],
      enlargedImageSrc: null
    }
  },

  mounted() {
    console.log('listData:', this.listData); // 调试信息
    this.initializeNodes();
  },

  updated() {
    this.initializeNodes();
  },

  methods: {
    initializeNodes() {
      this.$nextTick(() => {
        this.nodes = this.$refs.timelineDesc

        this.nodes.forEach(el => {
          if (el.clientHeight > 60) {
            el.classList.add("hidden-3")
            el.classList.add("flow-content")
          }
        })
      })
    },

    toggleExpand(e) {
      const index = e.currentTarget.getAttribute("data-index")
      const expand = e.currentTarget.getAttribute("data-expand")

      if (expand === "false") {
        this.nodes[index].classList.remove("hidden-3")
        e.currentTarget.setAttribute("data-expand", "true")
        e.currentTarget.querySelector("span").innerText = "收起"
        e.currentTarget.querySelector("svg").classList.add("rotate")
      }
      else {
        this.nodes[index].classList.add("hidden-3")
        e.currentTarget.setAttribute("data-expand", "false")
        e.currentTarget.querySelector("span").innerText = "展开更多"
        e.currentTarget.querySelector("svg").classList.remove("rotate")
      }
    },

    enlargeImage(src) {
      this.enlargedImageSrc = src;
    },

    closeEnlargedImage() {
      this.enlargedImageSrc = null;
    }
  }
};
</script>

<style lang="less" scoped>
/* 样式部分保持不变 */
.timeline-container {
  /* 如果需要，可以在这里添加容器样式 */
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.timeline {
  color: #fff;

  .timeline-event {
    border-left: 2px solid rgba(255,255,255,.1);
    padding-left: 20px;
    padding-bottom: 25px;
    font-size: 14px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -2px;
      width: 2px;
      height: 6px;
      background-color: steelblue;
    }

    .timeline-event-thumbnail {
      margin-bottom: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        font-size: 13px;
        color: rgba(255,255,255,.4);
      }
    }

    .title {
      margin-bottom: 6px;
      color: steelblue;
    }

    .expand-more {
      line-height: 30px;
      color: rgb(133, 181, 221);
      font-size: 13px;
      display: none;
      justify-content: flex-end;
      align-items: center;
      transition: .1s;

      span {
        margin-right: 3px;
      }

      &:active {
        filter: brightness(60%);
      }

      svg.rotate {
        transform: rotate(180deg);
      }
    }

    .desc {
      font-size: 13px;
      line-height: 20px;

      &.hidden-3 {
        height: 60px;
        overflow: hidden;
      }

      &.flow-content + .expand-more {
        display: flex;
      }
    }
  }
}

.jin-flash {
  border-radius: 4px;
  --pdLeft: 89px;
  --pdLeftMobile: 16px;
  --smallMargin: 10px;
  --normalMargin: 12px;
  --bigMargin: 16px;
}

.timeline-event.jin-flash-item {
  .timeline-event-copy.item-right {
    .right-pic.img-intercept.flash-pic {
      margin-top: 10px;
      width: 162px;  // 设置宽度为162px
      height: 162px; // 保持宽高比为1:1
      border-radius: 3px;
      cursor: pointer;
      overflow: hidden; // 确保图片不会溢出容器

      .img-container.show-shadow {
        position: relative;
        width: 100%;
        height: 100%;

        &:after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(35, 34, 115, .04);
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover; // 确保图片填满容器且不变形
        }
      }
    }
  }
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
</style>