<template>
  <div class="public-page register-page">
    <van-nav-bar title="注册" left-arrow @click-left="$router.back(-1)"/>

    <div class="main-container custom-form">
      <van-form @submit="onSubmit">
        <div class="input-item">
          <van-cell-group>
            <van-field
            label-width="50" v-model="username" label="用户名" placeholder="请输入用户名" name="username"
            :rules="[{ required: true, message: '请填写用户名' }]" />
          </van-cell-group>
        </div>

        <div class="input-item">
          <van-cell-group>
            <van-field
            label-width="50" v-model="password" type="text" label="密码" placeholder="请输入密码" name="password"
            :rules="[{ required: true, message: '请填写密码' }]" />
          </van-cell-group>
        </div>

        <div class="input-item1">
          <van-cell-group class="phone1">
            <van-field
              label-width="50" v-model="phoneNumber" label="手机号" placeholder="请输入手机号" name="phone_number"
              :rules="[{ required: true, message: '请填写手机号' }]" @input="onPhoneNumberInput" />
          </van-cell-group>
          <van-button class="send-code" @click="sendVerificationCode" :disabled="isSendingCode || countdown > 0">
            {{ countdown > 0 ? `${countdown}秒后重发` : '发送验证码' }}
          </van-button>
        </div>
        <div class="input-item">
          <van-cell-group>
            <van-field
            label-width="50" v-model="verificationCode" label="验证码" placeholder="请输入验证码" name="verification_code"
            :rules="[{ required: true, message: '请填写验证码' }]" />
          </van-cell-group>
        </div>

        
        <van-button class="submit" block native-type="submit" :disabled="isRegisterDisabled">立即注册</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { register } from '@/api/user'
import { sendSMS } from '@/api/user'

export default {
  data() {return {
    username: "",
    password: "",
    phoneNumber: "",
    verificationCode: "",
    isSendingCode: false,  // 控制发送验证码按钮是否可点击
    countdown: 0,  // 倒计时的秒数
    countdownTimer: null,  // 用于存储 setInterval 的返回值
    isRegisterDisabled: true
  }},
  methods: {
    async sendVerificationCode() {
      if (this.isSendingCode || this.countdown > 0) {
        return;  // 如果已经在发送状态或在倒计时中，则不再执行
      }
      this.isSendingCode = true;
      try {
        const response = await sendSMS({ phone_number: this.phoneNumber });
        // 假设你的API在请求成功时返回一个具有code属性的对象，且code为200
        if (response.code === 200) {
          console.log('验证码发送成功', response.message);
          this.startCountdown();
          this.isRegisterDisabled = false;
        } else {
          console.error('验证码发送失败', response.message);
        }
    } catch (error) {
      console.error('验证码发送失败', error);
    } finally {
      this.isSendingCode = false;  // 确保释放发送状态
    }
    },
    startCountdown() {
      this.countdown = 60;
      this.countdownTimer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(this.countdownTimer);
          this.countdownTimer = null;
        }
      }, 1000);
    },
    async onSubmit(values) {
      const res = await register(values)
      
      if (res.code === 400) {
        this.$toast("该用户名已存在")
      } else if (res.code === 200) {
        this.$toast("注册成功")
        this.$router.replace("/login")
      } else {
        this.$toast("发生未知错误")
      }
    },
    beforeDestroy() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);  // 清理计时器
    }
  },
  onPhoneNumberInput() {
    this.isRegisterDisabled = true;  // 当手机号输入框的值发生改变时，将按钮禁用
  }
}
}
</script>

<style lang="less" scoped>
.custom-form {
  padding: 30px;

  .input-item {
    margin-bottom: 10px;
    background-color: rgba(255,255,255,.03);
    border-radius: 10px;
  }

  .input-item1 {
    display: flex; // 使用Flexbox布局
    align-items: center; // 垂直居中对齐
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: rgba(255,255,255,.03);

    .phone1{
      flex-grow: 1;
    }
    .send-code {
      width: 100px;
      background-color: #3296fa;
      color: #fff;
      text-align: center;
      font-size: 14px;
      border-radius: 10px;
      margin-left: 10px; // 设置与手机号输入框的间距
      padding: 10px; // 移除默认内边距
    }
  }

  @media (max-width: 600px) {
    .input-item1 {
      align-items: flex-start; // 在小屏幕上左对齐

      .phone1 {
        width: 65%; // 在小屏幕上宽度100%
      }

      .send-code {
        margin-left: 0; // 在小屏幕上移除左边距
        width: 35%; // 在小屏幕上宽度100%
      }
    }
  }
  .submit {
    height: 42px;
    transition: .2s;
    background-color: steelblue;
    color: #fff;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .inline-item {
    display: inline-block;
  }

  .submit:active {
    background-color: rgb(35, 85, 126);
  }
}
</style>
