<template>
  <div class="layout-container">
    <router-view />

    <van-tabbar :value="current" fixed active-color="steelblue">
      <van-tabbar-item
        v-for="el in bottomBar"
        :key="el.label"
        replace
        :name="el.router"
        :icon="el.icon"
        @click="jumpRouter(el.router)"
        >{{ el.label }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { verifyVIP } from '@/api/user'

export default {
  name: "layout",
  data() {
    return {
      bottomBar: [
        {
          label: "首页",
          icon: "home-o",
          router: "/home",
        },
        {
          label: "市场",
          icon: "bag-o",
          router: "/market",
        },
        {
          label: "工具",
          icon: "discount-o",
          router: "/tool",
        },
        {
          label: "我的",
          icon: "user-circle-o",
          router: "/my",
        },
      ],
    };
  },
  computed: {
    current() {
      return this.$route.path
    }
  },
  methods: {
    async jumpRouter(router) {
      if (router === '/tool') {
        const res = await verifyVIP()
        console.log(res)
      }
      this.$router.push(router)
    }
  }
};
</script>

<style scoped>

</style>
