import store from "@/store"
import instance from "@/utils/request"

export const login = data => instance({
  url: '/login',
  method: 'post',
  data
})

export const register = data => instance({
  url: '/register',
  method: 'post',
  data
})

export const sendSMS = data => instance({
  url: '/send_sms',
  method: 'post',
  data
})

export const verifyVIP = () => instance({
  url: '/verify_vip',
  method: 'post',
  data: {
    token: store.getters.getToken
  }
})

export const uploadFile = data => instance({
  url: '/upload',
  method: 'post',
  headers: {
    "Content-Type": "multipart/form-data"
  },
  data
})

export const applicationMaterials = data => instance({
  url: '/api/info-insert',
  method: 'post',
  data
})

export const user_certification = data => instance({
  url: '/update-user-info',
  method: 'post',
  data
})
