<template>
  <div class="public-page tool-page">
    <van-nav-bar title="测量工具页面" />

    <div class="main-container">
      <!-- 提示 -->
      <div class="top-prompt">
        -- 本测试结果仅供参考，以实际化验结果为准 --
      </div>

      <!-- 顶栏 -->
      <div class="top-bar flex-">
        <div class="left flex-">
          <select class="c-select groups" @change="optionChange($event)">
            <option v-for="el in groupData" :key="el.label" :value="el.label"
              :selected="el.label === currentGroup.label">
              {{ el.label }}
            </option>
          </select>
          <div class="c-button ml insert-group" @click="insertGroup">添加组别</div>
          <div class="c-button ml plain delete-group" @click="deleteGroup" v-if="groupData.length > 1">×</div>
        </div>
        <div class="right">
          <div class="c-button" @click="exportGroupsData">导出数据</div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="list-container">
        <div class="list">
          <div class="table-header flex- tr-32">
            <div class="label">名称</div>
            <div class="label">数值</div>
            <div class="label">结果</div>
          </div>
          <div class="table-body list--" v-if="currentGroup.list">
            <div class="tr flex- tr-32 tr-number-item" v-for="(el, i) in currentGroup.list" :key="el.label">
              <div class="label">{{ el.label }}</div>
              <div class="label">
                <input type="text" class="c-input" v-model="el.value" @blur="formulaResult" />
              </div>
              <div class="label gray label-result">{{ resultValues[i] }}</div>
            </div>

            <div class="small-title">-- 微调馏程 --</div>

            <div class="tr flex- tr-32 tr-number-item" v-for="(el, i) in currentGroupBottom" :key="i">
              <div class="label">{{ el.label }}</div>
              <div class="label"></div>
              <div class="label gray label-result">{{ el.value }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 导出数据列表 -->
      <div class="export-data-list" v-show="exportActive">
        <div class="cancel " @click="exportActive = false">↼</div>
        <div class="list-table" ref="canvasToPic">
          <!-- 水印底图 -->
          <div class="watermark-backg" ref="watermarkMask">
            <div class="label" v-for="el in 60" :key="el">夜油行</div>
          </div>
          
          <!-- 提示 -->
          <div class="top-prompt" style="text-align: left;">本测试结果仅供参考，以实际化验结果为准</div>

          <!-- 标签列 -->
          <div class="column column-label">
            <div class="label">标签</div>
            <div class="label" v-for="el in labels" :key="el">{{ el }}</div>
          </div>

          <!-- 各组数据 -->
          <div class="column column-group" v-for="el in groupData" :key="el.label">
            <div class="label">{{ el.label }}</div>
            <div class="label" v-for="data in el.list" :key="data.label">{{ data.value }}</div>
          </div>

          <!-- 计算结果 -->
          <div class="column column-result">
            <div class="label">结果</div>
            <div class="label" v-for="(el, i) in resultValues" :key="i">{{ el }}</div>
          </div>

          <!-- 微调馏程 -->
          <div class="column column-result-2">
            <div class="label">微调</div>
            <div class="label" v-for="(el, i) in getGroupBottomExportData" :key="i">{{ el }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { uploadFile } from '@/api/user'

export default {
  data() {
    return {
      // 测试数据
      testData: [
        // [0, 0, 0, 350, 0, 400, 400, 800, 1000, 0, 0, 0, 0, 0, 0],
        // [99.5, 98.5, 120, 120, 109, 95.5, 120, 98, 68, 90, 98, 86, 95.8, 97, 80],
        // [0.8, 0.79, 0.87, 0.74, 0.88, 0.7, 0.9, 0.8, 0.68, 0.65, 0.88, 0.75, 0.74, 0.74, 0.71],
        // [2, 1, 2, 3, 0, 10, 2, 8, 2, 10, 12, 1, 4, 4, 23],
        // [4.6, 0.85, 0.2, 0.2, 0, 0, 1.1, 0, 0.2, 2.66, 0, 0, 0.65, 0.38, 1.5],
        // [0, 0, 0, 0, 0, 0, 0, 13, 0, 0, 0, 0, 2.6, 0.8, 63]
      ],
      // 标签集
      labels: [
        "吨数",
        "辛烷值",
        "密度",
        "硫",
        "苯",
        "烯烃",
        "芳烃",
        "蒸汽压",
        "氧",
        "甲醇",
        "硅",
        "馏程10%",
        "馏程50%",
        "馏程90%",
        "终馏点",
        "单价",
      ],
      // 副标签集
      labelsBottom: [
        "馏程10%$-6",
        "馏程50%$-10",
        "馏程90%$+33",
        "终馏点$+45",
      ],
      // 组数据
      groupData: [],
      // 当前组
      currentGroup: {},
      currentGroupBottom: [],
      // 当前计算结果
      resultValues: [],
      exportActive: false
    };
  },
  computed: {
    getGroupBottomExportData() {
      return [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ...this.currentGroupBottom.map(el => el.value),
        ""
      ]
    }
  },
  methods: {
    // 导出所有组数据保存为图片
    exportGroupsData() {
      this.exportActive = true

      this.$nextTick(() => {

        // 动态计算 list-table 的宽度
        const list_table = this.$refs.canvasToPic
        const sub_nodes = Array.from(list_table.childNodes).slice(2)
        let resultWidth = 0

        sub_nodes.forEach(el => {
          resultWidth += el.clientWidth
        })
        list_table.style.width = resultWidth + 40 + "px"
        this.$refs.watermarkMask.style.width = resultWidth + 40 + "px"

        // 导出图片
        html2canvas(this.$refs.canvasToPic, {
          backgroundColor: "#161220"
        }).then(canvas => {
          canvas.toBlob(async blob => {
            if (blob) {
              const res = await uploadFile({ file: blob })

              const a = document.createElement("a")
              a.href = res.data
              a.style.display = "none"
              a.setAttribute("download", Math.random() + ".png")
              this.$toast("图片生成成功，由于权限问题，请手动截图！")
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)

              

              console.log(res.data)
            } else {
              window.alert("图片数据转换失败")
            }
          })
        })

      })
    },

    // 组数据模型
    createListData() {
      return this.labels.map((el) => {
        return {
          label: el,
          value: "0",
        };
      })
    },

    // 公式模板(吨数 / 密度 * ?? / (总吨数 / 总密度))
    innerCommonFormula(type, tonnage, density) {
      const groupData = this.groupData

			let historyString = ""
			const result = groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => el.value)
				const find = top.list.find(el => el.label === type)
				
				if (index) historyString += " + "
				historyString +=
				`吨数${values[0].toFixed(4)} / 密度${values[2].toFixed(4)} * ${ type }${find.value.toFixed(4)} / (总吨数${tonnage.toFixed(4)} / 总密度${density.toFixed(4)})`
				
				return sum + values[0] / values[2] * find.value / (tonnage / density)
			}, 0) || 0
			
			// console.log(`${ type } -> ` + result.toFixed(4) + ": " + historyString)
			return result
		},

    // 计算函数
    formulaResult() {
      const groupData = this.groupData
      groupData.forEach(el => {
        el.list.forEach(el => {
          if (isNaN(el.value)) {
            el.value = 0
          } else {
            el.value = Number(el.value)
          }
        })
      })

      let historyString = "("
			// 吨数
			const tonnage = groupData.reduce((sum, top, index) => {
				if (index) historyString +=  " + "
				
				return sum + top.list.reduce((result, inner) => {
					if (inner.label === "吨数") {
						historyString += `吨数${ Number(inner.value).toFixed(4) }`
					}
					return result + (inner.label === "吨数" ? Number(inner.value) : 0)
				}, 0)
			}, 0)
			historyString += ")"
			// console.log("总吨数 -> " + tonnage.toFixed(4), historyString)


      // 辛烷值
			historyString = ""
			const octaneNumber = groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => Number(el.value))
				
				if (index) historyString +=  " + "
				else historyString += "("
				historyString += `吨数${ values[0].toFixed(4) } * 辛烷值${ values[1].toFixed(4) }`
				
				return sum + values[0] * values[1]
			}, 0) / tonnage || 0
			historyString += `) / 总吨数${ tonnage.toFixed(4) }`
			// console.log("总辛烷值 -> " + octaneNumber.toFixed(4), historyString)


      // 密度
			historyString = ""
			const density = tonnage / groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => el.value)
				
				if (index) historyString +=  " + "
				else historyString += `总吨数${ tonnage.toFixed(4) } / (`
				historyString += `吨数${ values[0].toFixed(4) } / 密度${ values[2].toFixed(4) }`
				
				return sum + values[0] / values[2]
			}, 0) || 0
			historyString += ")"
			// console.log("总密度 -> " + density.toFixed(4), historyString)


      // 硫
			historyString = ""
			const sulfur = groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => el.value)
				
				return sum + values[0] * values[3] / tonnage
			}, 0) || 0


      const benzene = this.innerCommonFormula("苯", tonnage, density)
			const olefin = this.innerCommonFormula("烯烃", tonnage, density)
			const aromaticHydrocarbon = this.innerCommonFormula("芳烃", tonnage, density)
			const vaporPressure = this.innerCommonFormula("蒸汽压", tonnage, density)


      // 氧
			// 吨数 * 氧
			historyString = ""
			const oxygen = groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => Number(el.value))
				
				if (index) historyString +=  " + "
				else historyString += "("
				historyString += `吨数${ values[0].toFixed(4) } * 氧${ values[8].toFixed(4) }`
				
				return sum + values[0] * values[8]
			}, 0) / tonnage || 0
			historyString += `) / 总吨数${ tonnage.toFixed(4) }`
			// console.log("氧 -> " + oxygen.toFixed(4) + ": " + historyString)


      const methanol = this.innerCommonFormula("甲醇", tonnage, density)
			const silicon = this.innerCommonFormula("硅", tonnage, density)
			const distillationRange10 = this.innerCommonFormula("馏程10%", tonnage, density)
			const distillationRange50 = this.innerCommonFormula("馏程50%", tonnage, density)
			const distillationRange90 = this.innerCommonFormula("馏程90%", tonnage, density)
			const distillationRangeEnd = this.innerCommonFormula("终馏点", tonnage, density)


      // 单价
			// (单价 * 吨数) / 总吨数
			historyString = ""
			const price = groupData.reduce((sum, top, index) => {
				const values = top.list.map(el => el.value)
				
				if (index) historyString += " + "
				historyString += `单价${ values[15] } * 吨数${ values[0] }`
				
				return sum + values[15] * values[0]
			}, 0) / tonnage || 0
			// console.log("单价 -> " + price.toFixed(4) + ": " + historyString)


      // 馏程微调
			const distillationRange10_6 = distillationRange10 ? distillationRange10 - 6 : 0
			const distillationRange50_10 = distillationRange50 ? distillationRange50 - 10 : 0
			const distillationRange90_33 = distillationRange90 ? distillationRange90 + 33 : 0
			const distillationRangeEnd_45 = distillationRangeEnd ? distillationRangeEnd + 45 : 0


      // 0吨数, 1辛烷值, 2密度, 3硫, 4苯, 5烯烃, 6芳烃, 7蒸汽压, 8氧, 9甲醇, 10硅,
			// 11馏程10%, 12馏程50%, 13馏程90%, 14终馏点, 15单价
				
			const resultValues = [
        tonnage,
        octaneNumber,
        density,sulfur,
        benzene,
        olefin,
        aromaticHydrocarbon,
			  vaporPressure,
        oxygen,
        methanol,
        silicon,
        distillationRange10_6,
        distillationRange50_10,
        distillationRange90_33,
        distillationRangeEnd_45,
        price
      ]
      const resultValuesBottom = [
        distillationRange10,
        distillationRange50,
        distillationRange90,
        distillationRangeEnd
      ]

      this.resultValues = resultValues.map(el => el.toFixed(4))
      this.currentGroupBottom = resultValuesBottom.map((el, i) => {
        return {
          label: this.labelsBottom[i].split("$")[0],
          value: el.toFixed(4)
        }
      })
    },

    // 添加组别
    insertGroup() {
      this.groupData.push({
        label: '',
        list: this.createListData()
      })

      this.groupData.forEach((el, i) => {
        el.label = `第${ i + 1 }组`
      })

      this.currentGroup = this.groupData[this.groupData.length - 1]
    },

    // 切换组别
    optionChange(e) {
      const find = this.groupData.filter(el => el.label === e.target.value)

      if (find.length) {
        this.currentGroup = find[0]
      }
    },

    // 删除组别
    deleteGroup() {
      this.$dialog.confirm({
        title: '删除组别',
        message: '你确定要删除' + this.currentGroup.label + "吗?",
      })
      .then(() => {
        const find = this.groupData.indexOf(this.currentGroup)
        this.groupData.splice(find, 1)
				this.currentGroup = this.groupData[this.groupData.length - 1]

        this.groupData.forEach((el, i) => {
          el.label = `第${ i + 1 }组`
        })

        this.formulaResult()
      })
      .catch(() => {})
    }
  },
  created() {
    // 初始化组数据
    if (this.testData.length) {
      this.testData[0].map((el, i) => {
        return {
          label: `第${i + 1}组`,
          list: this.labels.map((ele, xi) => {
            return {
              label: ele,
              value: this.testData[xi] ? this.testData[xi][i] : 0,
            }
          }),
        }
      })
    } else {
      // 填入第一组空白数据
      this.groupData.push({
        label: `第1组`,
        list: this.createListData()
      })
    }
    this.currentGroup = this.groupData[this.groupData.length - 1]

    this.formulaResult()
    // this.exportGroupsData()
  },
}
</script>

<style scoped>
.tool-page {
  color: #fff;
}

.tool-page select {
  color: #fff;
}

.top-prompt,
.small-title {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
}

.small-title {
  margin-top: 30px;
  margin-bottom: 10px;
}

header.mui-bar {
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.mui-content {
  background-color: #1c1c1e;
}

.c-button,
.c-select {
  display: inline-block;
  padding: 0 10px;
  line-height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  font-size: 14px;
  transition: 0.2s;
}

.c-button.plain {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}

.c-select {
  width: 86px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
}

.c-button:active,
.c-select:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.main-container {
  padding: 15px;
  height: calc(100vh - 100px);
  overflow: auto;
}

.flex- {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header {
  margin-top: 20px;
  /* background-color: rgba(255,255,255,.2); */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 5px;
}

.table-header .label {
  line-height: 34px;
  background-color: transparent;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 10px;
}

.c-input {
  width: 100%;
  height: 28px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  outline: none;
  transition: 0.1s;
}

.c-input:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.tr-32 .label {
  width: 32%;
}

.tr {
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
}

.tr .label {
  background-color: transparent;
}

.tr .label {
  padding: 0 10px;
}

.table-body .tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.12);
}

.gray {
  color: gray;
}

.xs- {
  width: 100%;
}

.submit {
  width: 100%;
  line-height: 40px;
  border-radius: 6px;
  background-color: steelblue;
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.2s;
}

.submit:active {
  filter: brightness(80%);
}

.ml {
  margin-left: 6px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
.watermark-backg {
  position: absolute;
  width: 300px;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  z-index: 0;
  overflow: hidden;
}
.watermark-backg .label {
  width: 20%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-40deg);
  color: rgba(255,255,255,.08);
}
.list-table .top-prompt {
  margin-bottom: 10px;
}
.list-table {
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  padding: 10px 20px;
  position: relative;
}
.list-table .column {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.list-table .column .label:nth-child(even) {
  background-color: rgba(255,255,255,.1);
}
.list-table .column .label {
  height: 32px;
  line-height: 32px;
  padding: 0 6px;
}
.export-data-list {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--themecolor);
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
}
.export-data-list::after {
  content: "";
  position: fixed;
  width: 50px;
  height: 100%;
  right: 0;
  top: 0;
  background-image: linear-gradient(to right, rgba(0,0,0,0), var(--themecolor) 70%);
  z-index: 3;
}
.export-data-list .cancel {
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 10px;
  background-color: rgba(255,255,255,.05);
  width: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  transition: .1s;
}
.export-data-list .cancel:active {
  background-color: rgba(255,255,255,.4);
}
</style>
