<template>
  <div class="public-page">
    <van-nav-bar title="供应商申请" left-arrow @click-left="$router.back(-1)"/>

    <van-form @submit="onSubmit">
      <van-field
        v-model="name"
        name="name"
        label="公司名称"
        placeholder="公司名称"
        :rules="[{ required: true, message: '公司名称不能为空' }]"
      />
      
      <van-field
        v-model="jyfw"
        name="jyfw"
        label="经营范围"
        placeholder="经营范围"
        :rules="[{ required: true, message: '经营范围不能为空' }]"
      />

      <van-field name="yyzz_url" label="上传营业执照" :rules="[{ required: true, message: '请上传营业执照' }]">
        <template #input>
          <van-uploader v-model="yyzz_url" max-count="1" max-size="4000000" />
        </template>
      </van-field>

      <van-field name="whz_url" label="上传危化证" :rules="[{ required: true, message: '请上传危化证' }]">
        <template #input>
          <van-uploader v-model="whz_url" max-count="1" max-size="4000000" />
        </template>
      </van-field>

      <van-field name="sfz_url" label="上传身份证" :rules="[{ required: true, message: '请上传身份证' }]">
        <template #input>
          <van-uploader v-model="sfz_url" max-count="1" max-size="4000000" />
        </template>
      </van-field>

      <div class="desc-text">需要加盖公章 上传扫描件或复印件即可 身份证正反面复印件放在同一张图片中</div>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { uploadFile, applicationMaterials } from '@/api/user'
import { set } from 'vue'

export default {
  data() {return {
    name: '',
    jyfw: '',
    yyzz_url: [],
    whz_url: [],
    sfz_url: []
  }},
  methods: {
    onSubmit(values) {
      // 上传所有图片
      const success = [false, false, false]
      const images = [
        values.yyzz_url,
        values.whz_url,
        values.sfz_url
      ]

      images.forEach(async (el, i) => {
        const res = await uploadFile(el[0])

        success[i] = res.data
        if (!success.filter(el => el === false).length) {
          // 全部图像上传完成
          this.submitAllInfo(success)
        }
      })
    },
    async submitAllInfo(images) {
      await applicationMaterials({
        name: this.name,
        jyfw: this.jyfw,
        yyzz_url: images[0],
        whz_url: images[1],
        sfz_url: images[2],
      })

      this.$toast("提交成功")
      this.name = ''
      this.jyfw = ''
      this.yyzz_url = []
      this.whz_url = []
      this.sfz_url = []

      setTimeout(() => {
        this.$router.back(-1)
      }, 800);
    }
  },
}
</script>

<style scoped>
.desc-text {
  margin-top: 20px;
  padding: 0 20px;
  color: rgba(255,255,255,.3);
  font-size: 14px;
  text-align: center;
}
</style>