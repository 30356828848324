<template>
  <div class="public-page">
    <van-nav-bar title="实名认证" left-arrow @click-left="$router.back(-1)"/>

    <div class="main-container">
      <div class="sut_input_item flex">
        <div class="label">真实姓名</div>
        <input type="text" placeholder="请填写姓名" v-model="form.rName">
      </div>
      <div class="sut_input_item flex">
        <div class="label">身份证号</div>
        <input type="text" placeholder="请填写身份证号" v-model="form.rId">
      </div>

      <div class="image-list">
        <div class="image-item">
          <div class="text-center">身份证正面</div>
          <van-uploader class="sut_uploader" v-model="form.sfz_url" multiple :max-count="1" />
        </div>

        <div class="image-item">
          <div class="text-center">身份证反面</div>
          <van-uploader class="sut_uploader" v-model="form.bsfz_url" multiple :max-count="1" />
        </div>
      </div>

      <div class="submit" @click="onSubmit">提交审核</div>

      <div class="desc">* 仅用于本站进行身份认证</div>
      <div class="desc">* 上传的图片需要字迹清晰、亮度均衡、清晰可见</div>
    </div>
  </div>
</template>

<script>
import { user_certification, uploadFile } from '@/api/user'

export default {
  data() {
    return {
      form: {
        rName: "",
        rId: "",
        sfz_url: [],
        bsfz_url: [],
      }
    };
  },

  methods: {
    vaildForm() {
      if (!this.form.rName.trim()) {
        this.$toast("请填写真实姓名")
        return false
      }

      if (!this.form.rId.trim()) {
        this.$toast("请填写身份证号")
        return false
      }

      if (!this.form.sfz_url.length) {
        this.$toast("请选择正面照")
        return false
      }

      if (!this.form.bsfz_url.length) {
        this.$toast("请选择反面照")
        return false
      }

      return true
    },
    async onSubmit() {
      if (!this.vaildForm()) return false

      let sfz_url, bsfz_url

      if (this.form.sfz_url.length) {
        const res = await uploadFile({ file: this.form.sfz_url[0].file })
        sfz_url = res.data
      }

      if (this.form.bsfz_url.length) {
        const res = await uploadFile({ file: this.form.bsfz_url[0].file })
        bsfz_url = res.data
      }

      this.user_certification(sfz_url, bsfz_url)
    },
    async user_certification(sfz_url, bsfz_url) {
      const res = await user_certification({
        ...this.form, sfz_url, bsfz_url
      })

      this.$router.back(-1)
    }
  },
}
</script>

<style lang="less" scoped>
.main-container {
  padding: 15px;
}
.sut_input_item {
  height: 44px;
  border-radius: 10px;
  background-color: rgba(255,255,255,.04);
  margin-bottom: 10px;
  padding: 0 15px;

  .label {
    width: 70px;
    color: #bfbfbf;
    font-size: 14px;
  }

  input {
    width: calc(100% - 70px);
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    text-align: right;
    font-size: 14px;
  }
}
.image-list {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.image-item {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.text-center {
  width: 100%;
  text-align: center;
  color: #efefef;
  margin-bottom: 10px;
  font-size: 15px;
  padding-right: 7px;
}
.submit {
  width: 220px;
  line-height: 38px;
  border-radius: 10px;
  background-color: steelblue;
  color: #fff;
  text-align: center;
  font-size: 14px;
  margin: 0 auto 30px;
}
.desc {
  text-align: center;
  font-size: 12px;
  color: rgba(255,255,255,.3);
  margin-bottom: 4px;
}
</style>
