import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const TOKEN_KEY = "$lss_token_$"
const NICKNAME_KEY = "$lss_nickname_$"

export default new Vuex.Store({
  state: {
    token: localStorage.getItem(TOKEN_KEY),
    username: localStorage.getItem(NICKNAME_KEY),
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getUsername(state) {
      return state.username
    }
  },
  mutations: {
    setToken(state, val) {
      state.token = val
      localStorage.setItem(TOKEN_KEY, val)
    },
    setUsername(state, val) {
      state.username = val
      localStorage.setItem(NICKNAME_KEY, val)
    }
  },
  actions: {
    
  },
  modules: {
  }
})
