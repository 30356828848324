<template>
  <div class="public-page login-page">
    <van-nav-bar title="登录" left-arrow @click-left="$router.back(-1)"/>

    <div class="mask login-mask">
			<div class="login-container">
				<div class="form">
					<div class="form-item">
						<div class="label">用户名</div>
						<input type="text" placeholder="请输入用户名" autocomplete="off" v-model="addForm.username">
					</div>
					
					<div class="form-item">
						<div class="label">密码</div>
						<input type="password" placeholder="请输入密码" autocomplete="off" v-model="addForm.password">
					</div>
					
					<div class="submit-login" @click="login">登录</div>
					
					<div class="bottom-operate">
						<div class="item" @click="$router.push('/register')">注册账号</div> |
						<div class="item" @click="$router.push('/custom-code')">忘记密码</div>
					</div>
					
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import { login } from '@/api/user'
import store from '@/store'

export default {
  created() {
    
  },
  data() {return {
    addForm: {
      username: "",
      password: ""
    }
  }},
  methods: {
    async login() {
      const res = await login(this.addForm)
      store.commit("setToken", res.data.token)
			store.commit("setUsername", this.addForm.username)

      this.$toast("登录成功")
      this.$router.replace({
        path: "/home"
      })
    }
  }
}
</script>

<style scoped>
.login-container {
	width: 100%;
  height: 100vh;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .header-top {
	line-height: 60px;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.login-container .header-top::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(255,255,255,.1), rgba(0,0,0,0));
}

.form {
	width: 80%;
	margin: 0 10%;
}

.form-item {
	width: 100%;
	height: 46px;
	margin-bottom: 10px;
	border-radius: 10px;
	background-color: rgba(255,255,255,.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
}

.form-item .label {
	background-color: transparent;
	font-size: 14px;
	width: 64px;
}

.form-item input {
  width: calc(100% - 64px);
	background-color: transparent !important;
	border: none;
	outline: none;
	height: 80%;
	margin-bottom: 0;
	font-size: 14px;
}

.submit-login {
	background-color: steelblue;
	margin-top: 20px;
	border-radius: 10px;
	text-align: center;
	line-height: 46px;
	transition: .1s;
}

.submit-login:active {
	filter: brightness(80%);
}

.auto-login {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.auto-login .label {
	margin-left: 5px;
}

.bottom-operate {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	position: absolute;
	bottom: 30px;
	width: 100%;
	left: 0;
}

.bottom-operate .item {
	margin: 0 10px;
	transition: .1s;
}

.bottom-operate .item:active {
	color: steelblue;
}

</style>
