<template>
  <div>
    <!-- 客服聊天界面容器 -->
    <iframe src="https://kefu.pree.cc/chatIndex?kefu_id=kefu2" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'KefuChat'
}
</script>
